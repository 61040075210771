<script setup lang="ts">
import { computed } from 'vue'
import type buttonProps from '@/types/components/buttonProps'
import LoadingSpinner from './LoadingSpinner.vue'

const props = withDefaults(defineProps<buttonProps>(), {
  block: false,
  color: 'primary',
  type: 'button',
  text: '',
  loading: false,
  disabled: false,
})

const btnColor = computed(() => {
  return {
    'indigo': 'indigo-btn',
    'primary': 'primary-btn',
    'light': 'light-btn',
    'success': 'success-btn',
    'error': 'error-btn',
    'secondary': 'secondary-btn',
    'dark': 'dark-btn',
    'transparent': 'transparent-btn'
  }[props.color]
})
</script>

<template>
  <button
    :type="type"
    class="btn"
    :class="[
      {'w-full': block},
      {'text-white': color !== 'light'},
      {'cursor-not-allowed': loading},
      btnColor
    ]"
    :disabled="loading || disabled"
    :data-cy="dataCy && `${dataCy}_cButton`"
  >
    <div class="flex items-center justify-center gap-x-3">
      <LoadingSpinner
        v-if="loading"
        :class="{'text-black': color == 'light'}"
      />
      <div class="flex items-center gap-x-2">
        <slot>
          {{ text }}
        </slot>
      </div>
    </div>
  </button>
</template>

<style>
.primary-btn {
  @apply bg-primary-100 hover:bg-primary-200
  active:bg-primary disabled:bg-primary-100/80;
  
  &.btn-active {
    @apply !bg-primary-active
  }
}

.indigo-btn {
  @apply bg-indigo-600 hover:bg-indigo-700 
  active:bg-indigo-500 disabled:bg-indigo-400;

  &.btn-active {
    @apply !bg-indigo-700
  }
}

.light-btn {
  @apply bg-white border text-gray-800 
  disabled:bg-gray-50 hover:bg-gray-100
  disabled:ring-0 active:bg-gray-200;

  &.btn-active {
    @apply !bg-gray-200
  }
}

.success-btn {
  @apply bg-green-600 hover:bg-green-700 
  active:bg-green-500 disabled:bg-green-500
}

.error-btn {
  @apply bg-red-600 hover:bg-red-700 
  active:bg-red-500 disabled:bg-red-500;

  &.btn-active {
    @apply !bg-red-700
  }
}

.transparent-btn {
  @apply bg-transparent hover:bg-white/5
}

.secondary-btn {
  @apply bg-gray-300 text-black hover:bg-gray-300
  active:bg-gray-300/90;

  &.btn-active {
    @apply !bg-gray-500 text-white
  }
}

.dark-btn {
  background-color: #232327;

  &.btn-active {
    background-color: #38383d;
  }
}
</style>