/* eslint-disable max-lines */
import { ref } from 'vue'
import type { 
  LotHuntScrubConstructor, 
  MarkerContainer, 
  MarkerType, 
  ScrubbingDetails
} from '@/types/map/lothunt-scrub';
import { useLotScrubbing } from '@/store/explore/lot-scrubbing';
import type { MapMarker } from '@/types/mapMarkers';

/* eslint-disable */
export class LotHuntScrub implements LotHuntScrubConstructor {
  private static _instance: LotHuntScrub | null = null;

  //Set for future use
  private _type: MarkerType = 'lot'
  //Marker holder for scrub
  private _markers: MarkerContainer[] = []
  //Total markers - shown for the count on info window
  private _totalMarkers: number = 0
  //Current marker to return and get based on _type
  private _currentMarker: MapMarker[] = [];
  //Index for scrolling through markers
  private _currentIndex: number = -1;
  private _currentLotNumber: number = 0;
  //Excluded markers
  _excludedMarkers = ref<string[]>([])

  private _enableScrubbing: boolean = true

  _lotNumber: number | null = null

  /**
   * Reference for the current shown marker
   * to hide/remove it from the map. (LotHunt Marker)
   */
  private _shownMarker: MapMarker | null = null
  private _hoveredMarker: MapMarker | null = null

  /**
   * Set to true to prevent unintentional hover on a 
   * marker when scrubbing.
   */
  _disableHoverState: boolean = false 

  private _LOT_OFFSET = 50
  private _lotScrubStore;

  constructor() {
    if (LotHuntScrub._instance) {
      return LotHuntScrub._instance
    }
    LotHuntScrub._instance = this;
    this._lotScrubStore = useLotScrubbing()
  }

  async getFirstMarker(hasFetched?: boolean): Promise<MapMarker> {
    const index = this._currentMarker.findIndex(
      marker => marker.attributes.lot_number === 1)

    if (index === -1 && !hasFetched) {
      await this._lotScrubStore?.fetchLots({
        min_lot_number: 1,
        max_lot_number: 100
      })
      return this.getFirstMarker()
    }

    this._currentLotNumber = 1
    this._shownMarker = this._currentMarker[index] 

    return this._currentMarker[this._currentLotNumber]
  }

  async getPreviousMarker(hasFetched?: boolean): Promise<MapMarker> {
    const index = this._currentMarker.findIndex(
      marker => marker.attributes.lot_number === (+this._currentLotNumber - 1))
    
    if (index === -1 && !hasFetched) {
      await this._lotScrubStore?.fetchLots(this.getLotFetchOffset())
      //Call this function again after fetching
      return this.getPreviousMarker(true)
    }

    this._currentLotNumber--
    this._shownMarker = this._currentMarker[index]
    
    return this._shownMarker!
  }

  async getNextMarker(hasFetched?: boolean): Promise<MapMarker> {
    const index = this._currentMarker.findIndex(
      marker => marker.attributes.lot_number === (this._currentLotNumber + 1))
    
    if (index === -1 && !hasFetched) {
      await this._lotScrubStore?.fetchLots(this.getLotFetchOffset())
      //Call this function again after fetching
      return this.getNextMarker(true)
    }

    this._currentLotNumber++
    this._shownMarker = this._currentMarker[index]
   
    return this._shownMarker!
  }

  async getLastMarker(hasFetched?: boolean): Promise<MapMarker> {
    const index = this._currentMarker.findIndex(marker => 
      marker.attributes.lot_number === this._totalMarkers)
      
    //Set current lot number to the number of total markers
    this._currentLotNumber = this._totalMarkers

    if (index === -1 && !hasFetched) {
      await this._lotScrubStore?.fetchLots({
        min_lot_number: this._totalMarkers - 100,
        max_lot_number: this._totalMarkers
      })
      return this.getLastMarker(true)
    }

    this._shownMarker = this._currentMarker[index]      
    return this._shownMarker!
  }

  async getMarkerByLotNumber(
    lotNumber: number,
    hasFetched?: boolean 
  ): Promise<MapMarker | null> {
    const index = this._currentMarker.findIndex(marker => 
      marker.attributes.lot_number === lotNumber)
    
    if (index === -1 && !hasFetched) {
      await this._lotScrubStore?.fetchLots({
        min_lot_number: this._totalMarkers - 100,
        max_lot_number: this._totalMarkers
      })

      return this.getMarkerByLotNumber(lotNumber, true)
    }
      
    this._shownMarker = this._currentMarker[index]
    return this._shownMarker
  }

  getMarkerBasedOnType(): void {
    this._currentMarker = this._markers.find(marker => 
        marker.type === this._type)?.markers ?? []
  }

  setCurrentIndexById(markerId: string) {
    const index = this._currentMarker.findIndex(marker => 
      marker.id === markerId)
    if (index === -1) return 

    this._currentIndex = index
  }

  getMarkerById(id: string): { marker: MapMarker, index: number } | null {
    const index = this._currentMarker.findIndex(marker => marker.id === id)
    
    if (index === -1) return null

    //Set current lot number based on the marker that has been clicked
    this._currentLotNumber = this._currentMarker[index].attributes.lot_number

    return {
      marker: this._currentMarker[index],
      index: index
    }
  }
  
  getLotFetchOffset() {
    const lotFetchOffset = { min_lot_number: 0, max_lot_number: 0 }
    
    const min_lot_number = this._currentLotNumber - this._LOT_OFFSET
    const max_lot_number = this._currentLotNumber + this._LOT_OFFSET

    lotFetchOffset.min_lot_number = min_lot_number < 0 ? min_lot_number : 0
    lotFetchOffset.max_lot_number = max_lot_number > this._totalMarkers ? 
      max_lot_number : this._totalMarkers

    return lotFetchOffset 
  }

  getCurrentMarker(): MapMarker {
    return this._currentMarker[this._currentLotNumber]
  }

  getScrubbingInfo(): ScrubbingDetails {
    return {
      _currentLotNumber: this._currentLotNumber,
      _totalMarkers: this._currentMarker.length
    }
  }

  /* Excluded Markers */
  excludeLot(apn: string): void {
    const index = this._excludedMarkers.value.indexOf(apn)
    if (index !== -1) {
      this._excludedMarkers.value.splice(index, 1) 
    } else {
      this._excludedMarkers.value.push(apn)
    }

    window.dispatchEvent(new Event('excluded-marker-updated'))
  }

  getExcludedMarkers(): string[] {
    return this._excludedMarkers.value
  }

  setExcludedMarkers(apns: string[]): void {
    this._excludedMarkers.value = apns
  }

  isExcluded(apn: string): boolean {
    return this._excludedMarkers.value.includes(apn) 
  }

  setMarkers(markers: MarkerContainer): void {
    const index = this._markers.findIndex(marker => 
      marker.type === markers.type);

    // Replace the existing marker having the same type
    // with the new one.
    if (index !== -1) {
      this._markers[index] = markers
    } else {
      this._markers.push(markers)
    }

    // Set marker for scrubbing
    this.getMarkerBasedOnType()
  }

  /**
   * Campaign is allowed up to 20k lots but due to some issue with 
   * the performance, shown lots is now limited to 500 only. 
   */
  setTotalMarkers(count: number) {
    this._totalMarkers = count
  }

  getTotalMarkers(): number {
    return this._totalMarkers
  }

  setEnableScrubbing(enabled: boolean): void {
    this._enableScrubbing = enabled
  }

  getEnableScrubbing(): boolean {
    return this._enableScrubbing
  }

  setHoveredMarker(marker: MapMarker | null): void {
    this._hoveredMarker = marker
  }

  setShownMarker(marker: MapMarker | null): void {
    this._shownMarker = marker
  }

  getShownMarker(): MapMarker | null {
    return this._shownMarker
  }

  getMarkersLength(): number {
    return this._currentMarker.length
  }
}