import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { defineStore } from 'pinia';
import api from '@/lib/axios';
import { useSaveCampaign } from './save-campaign';
import { LOADING, RESPONSE, ROUTE } from '@/types/enums';
import type { 
  CampaignsApiResponse, 
  CampaignsResponseData,
} from '@/types/api-response/campaigns';
import { processing } from '@/composables/useLoadingState';
import { useExploreStore } from '../explore';
import type { CampaignStatus, CampaignUpdateForm } from '@/types/campaign';
import { useAdvancedFilters } from '@/composables/useAdvancedFilters';
import { LotHuntScrub } from '@/modules/map/lothuntScrub';

export const useCampaignStore = defineStore('user-campaign', () => {
  const campaigns = ref<CampaignsApiResponse>()
  const route = useRoute()
  const router = useRouter()
  const toast = useToast()
  const filters = useAdvancedFilters()
  const explore = useExploreStore()
  const saveCampaign = useSaveCampaign()

  const lotScrub = new LotHuntScrub()

  const updateCampaignData = ref<CampaignUpdateForm>({ 
    id: '', name: '', conversions: 0 })

  const setCampaignData = async (campaign: CampaignsResponseData) => {
    updateCampaignData.value.id = campaign.id
    updateCampaignData.value.name = campaign.attributes.name
  }

  const createCampaign = async (name: string, type: 'upload' | 'explore'): 
    Promise<string | undefined> => { 
      processing.value = LOADING.CREATE_CAMPAIGN
      const response = await api.post<{ data: CampaignsResponseData }>(
        'campaigns', 
        { campaign: { name, campaign_type: type } }
      )   

      if (response.status === RESPONSE.CREATED) {
        sessionStorage.removeItem('lots')
        sessionStorage.removeItem('comps')
        lotScrub.setExcludedMarkers([])

        campaigns.value?.data?.unshift(response.data.data)
        selectedCampaign.value = response.data.data
        if (type === 'explore') {
          sessionStorage.setItem('campaign', 
            JSON.stringify(selectedCampaign.value))
        }

        return response.data.data.id 
      }
  }

  const getAllCampaigns = async (filtered?: boolean): 
    Promise<boolean | undefined> => {
      processing.value = LOADING.GET_ALL_CAMPAIGNS
      const response = await api.get<CampaignsApiResponse>('campaigns')

      if (response.status === RESPONSE.SUCCESS) {
        if (filtered) {
          campaigns.value = {
            data: response.data.data?.filter(
              campaign => campaign.attributes.campaign_type === 'explore'
            ),
            meta: response.data.meta
          }
        } else {
          campaigns.value = response.data
        }
        return true
      }
  }

  const selectedCampaign = ref<CampaignsResponseData | null>(null)

  const getSelectedCampaignData = () => ({
    id: selectedCampaign!.value!.id,
    label: selectedCampaign!.value!.attributes.name,
    name: selectedCampaign!.value!.attributes.name,
    value: {
      lat: selectedCampaign?.value?.attributes.average_lat!,
      lng: selectedCampaign?.value?.attributes.average_lng!,
    }
  })

  const selectedCampaignStatus = computed(
    () => selectedCampaign?.value?.attributes?.status)

  const checkIfCampaignPersist = (): boolean => {
    const campaign: CampaignsResponseData = JSON.parse(
        sessionStorage.getItem('campaign') || '{}')

    if (campaign.id && campaign?.id === route.params?.campaignId) {
      selectedCampaign.value = campaign
      return true 
    } 
    return false
  }

  //eslint-disable-next-line complexity
  const getCampaign = async (campaign: string, preventRedirect?: boolean):
    Promise<CampaignsResponseData | undefined> => {
      explore.mdnDaysOnMarket = null 
      saveCampaign.resetAutoSave()
      
      if (checkIfCampaignPersist()){
        filters.setAdvancedFilters(selectedCampaign.value!)
        lotScrub.setExcludedMarkers(
          selectedCampaign.value!.attributes?.lot_filters?.excluded_apns ?? [])
          
        return selectedCampaign.value!
      }
      
      processing.value = LOADING.GET_CAMPAIGN
      const response = await api.get<{ 
        data: CampaignsResponseData 
      }>(`campaigns/${campaign}`)
      
      saveCampaign.resetAutoSave() 
      if (response.status === RESPONSE.SUCCESS){
        const campaignType = response.data.data.attributes.campaign_type
        if (campaignType === 'upload' && route.path.startsWith('/explore') 
         && !preventRedirect) {
          await router.push({ name: ROUTE.EXPLORE })
        }
        selectedCampaign.value = response.data.data
        filters.setAdvancedFilters(response.data.data)
        if (campaignType === 'explore') {
          sessionStorage.setItem('campaign', JSON.stringify(response.data.data))
        }
        lotScrub.setExcludedMarkers(
          selectedCampaign.value!.attributes?.lot_filters?.excluded_apns ?? [])
      
        return response.data.data
      }
      
      if (response.status === RESPONSE.NOT_FOUND) {
        toast.error('Campaign not found')
        await router.replace({ name: ROUTE.EXPLORE })
      }
  }

  const updateCampaignStatus = async (
      campaignId: string, 
      status: CampaignStatus | null
    ): Promise<boolean | undefined> => {
      processing.value = LOADING.PAUSE_CAMPAIGN
      const response = await api.put<{ data: CampaignsResponseData}>(
        `campaigns/${campaignId}`, { campaign: { status } }
      )

      if (response.status === RESPONSE.SUCCESS) {
        campaigns.value?.data?.forEach((campaign, index) => {
          if (campaign.id === campaignId) {
            if (campaigns.value && campaigns.value.data && 
                campaigns.value.data[index]) {
              campaigns.value.data[index] = response.data.data
            }
          }
        })
        return true 
      }
  }

  //eslint-disable-next-line complexity
  const updateCampaign = async (): Promise<boolean | undefined> => {
    processing.value = LOADING.UPDATE_CAMPAIGN
    const { id, ...form } = updateCampaignData.value
    const response = await api.put<{ 
      data: CampaignsResponseData 
    }>(`campaigns/${id}`, { campaign: { ...form } })

    if (response.status === RESPONSE.SUCCESS) {
      toast.success('Campaign has been updated')
      selectedCampaign.value = response.data.data
      if (selectedCampaign.value.attributes?.campaign_type === 'explore') {
        sessionStorage.setItem('campaign', JSON.stringify(response.data.data))
      }
      return true
    }
  }

  return { 
    campaigns, createCampaign, getAllCampaigns, 
    getCampaign, getSelectedCampaignData, selectedCampaign,
    setCampaignData, updateCampaignStatus,
    updateCampaignData, updateCampaign, selectedCampaignStatus
  }
})