  import { ref, shallowRef } from 'vue'
  import { defineStore } from 'pinia';
  import api from '@/lib/axios';
  import { LOADING, RESPONSE } from '@/types/enums';
  import type { 
    CompsApiResponse, 
    CompsData, 
    CompsResponseData 
  } from '@/types/api-response/comps';
  import type { LotsApiResponse } from '@/types/api-response/lots';
  import { useMapHelpers } from '@/composables/google-map/useMapHelpers';
  import { processing } from '@/composables/useLoadingState';
  import { useCampaignStore } from './campaign/campaign';
  import { useAdvancedFilters } from '@/composables/useAdvancedFilters';
  import { useHandleState } from '@/composables/useHandleState';
  import { useFilters } from '@/composables/useFilters';
  import { useFormValidate } from '@/composables/useFormValidate';
  import { useSaveCampaign } from './campaign/save-campaign';
  import { useToast } from 'vue-toastification';
  import { LotHuntScrub } from '@/modules/map/lothuntScrub';
  import axios from 'axios';
  import type { MapMarker } from '@/types/mapMarkers';
  import { format } from '@/utils/numberFormat';

  export const useExploreStore = defineStore('explore-map', () => {
    const campaign = useCampaignStore()
    const filter = useFilters()
    const saveCampaign = useSaveCampaign()
    const markers = useHandleState()
    const advancedFilter = useAdvancedFilters()
    const zod = useFormValidate()
    const toast = useToast()
    const { selectedPolygon } = useMapHelpers()
    
    const comps = shallowRef<CompsData[] | undefined>([])

    const checkForPersistedComps = () => {
      const storedComps = JSON.parse(sessionStorage.getItem('comps') || '{}')
      if (! Array.isArray(storedComps)) return false 
      comps.value = storedComps 
      return true
    }

    const compAbortController = shallowRef<AbortController | null>(null)
    const mdnDaysOnMarket = ref<number | null>(0)
    //eslint-disable-next-line complexity
    const filterComps = async (fetch: boolean = false): Promise<void> => {
      if (! ['comps', 'any'].includes(filter.fetchData.value)) return 
      if (checkForPersistedComps() && !fetch) return
      if (! filter.checkIsFilterValid()) return
      if (! (markers.mapMarkerLegend.value as number[]).includes(2) &&
        !(markers.mapMarkerLegend.value as number[]).includes(3)) return

      //Prevent duplicate fetching
      if (compAbortController.value) {
        compAbortController.value.abort()
      }

      compAbortController.value = new AbortController()

      processing.value = LOADING.FETCHING_LOTS
      mdnDaysOnMarket.value = null
      const response = await api.post<CompsApiResponse>(
        'comps', { 
          ...filter.filters, 
          ...filter.mapBoundsFilter,
          status: filter.getCompStatus(),
          polygon: selectedPolygon.value 
        }, 
        { signal: compAbortController.value?.signal }
      )

      //Reset fetchData to any
      filter.fetchData.value = 'any'

      if (response.status === RESPONSE.SUCCESS) {
        comps.value = response.data.data
        mdnDaysOnMarket.value = response.data?.meta?.median_days_on_market
        sessionStorage.setItem('comps', JSON.stringify(comps.value))
        filterCompsByType(response.data.data)
      } else {
        if (axios.isCancel(response)) return 
        const errorMessage = zod.errors.value[Object.keys(zod.errors.value)[0]]
        toast.error(errorMessage ?? 'Something went wrong')
      }
    }

    const comps_sold = shallowRef<MapMarker[]>([])
    const comps_sale = shallowRef<MapMarker[]>([])

    const filterCompsByType = (comps: CompsResponseData[]) => {
      comps_sale.value = comps.filter(comp => 
          comp.attributes.status === 'for_sale')
          .map(comp => ({
            ...comp,
            formatted_price: format(comp.attributes.price, true)
          })) as unknown as MapMarker[]
      comps_sold.value = comps.filter(comp => 
          comp.attributes.status === 'sold' || 
          comp.attributes.status === 'pending')
          .map(comp => ({
            ...comp,
            formatted_price: format(comp.attributes.price, true)
          })) as unknown as MapMarker[]
    }

    const lots = ref<MapMarker[]>([])
    const totalLots = ref<number>(0)

    const checkForPersistedLots = () => {
      const storedLots = JSON.parse(sessionStorage.getItem('lots') || '{}')
      if (! Array.isArray(storedLots)) return false 
      lots.value = storedLots 
      return true
    }

    const lotScrub = new LotHuntScrub()
    const lotAbortController = shallowRef<AbortController | null>(null)
    //eslint-disable-next-line complexity 
    const filterLots = async (fetch: boolean = false, 
      controller?: AbortController): Promise<void> => {
      await saveCampaign.saveCampaign()

      if (! ['lots', 'any'].includes(filter.fetchData.value)) return 
      if (selectedPolygon.value.length === 0) return 
      if (! filter.checkIsFilterValid()) return
      if (checkForPersistedLots() && !fetch) return
      if (! (markers.mapMarkerLegend.value as number[]).includes(1)) return
      
      const { 
        min_price: min_estimated_price, min_acres,
        max_price: max_estimated_price, max_acres,
      } = filter.filters

      //Prevent duplicate fetching
      if (lotAbortController.value) {
        lotAbortController.value.abort()
      }
      lotAbortController.value = new AbortController()

      processing.value = LOADING.FETCHING_LOTS
      const response = await api.post<LotsApiResponse>(
        'lots', {
          min_estimated_price, max_estimated_price, min_acres, 
          max_acres, ...filter.mapBoundsFilter,
          polygon: selectedPolygon.value,
          campaign_id: campaign.selectedCampaign?.id,
          ...advancedFilter.getAdvancedFilter()
        }, { signal: controller?.signal }
      )   
      //Reset fetchData to any
      filter.fetchData.value = 'any'

      if (response.status === RESPONSE.SUCCESS) {
        lots.value = response.data.data! as unknown as MapMarker[]
        totalLots.value = response.data?.meta?.count
        sessionStorage.setItem('lots', JSON.stringify(lots.value))
        lotScrub.setMarkers({ 
          type: 'lot', 
          markers: lots.value as unknown as MapMarker[]
        })
        lotScrub.setTotalMarkers(totalLots.value)
      } else {
        if (axios.isCancel(response)) return 

        const errorMessage = zod.errors.value[Object.keys(zod.errors.value)[0]]
        toast.error(errorMessage ?? 'Something went wrong')
      }
    }

    const clearPolygon = () => selectedPolygon.value.length = 0

    const clearLotsAndComps = () => {
      comps.value = []
      comps_sale.value = []
      comps_sold.value = []
      lots.value = []
      sessionStorage.removeItem('lots')
      sessionStorage.removeItem('comps')
    }
    
    const activeExcludedLots = (excludedLots: string[]) => 
      excludedLots.filter(excludedApn => lots.value.some(lot => 
        lot.attributes.apn === excludedApn))

    const addUniqueMarkers = (markers: MapMarker[]) => {
      const existingIds = new Set(lots.value.map(marker => marker.id))

      //Add ids of the new markers
      markers.forEach(marker => {
        if (! existingIds.has(marker.id)) {
          lots.value.push(marker)
          existingIds.add(marker.id)
        }
      })

      //Set the markers again
      lotScrub.setMarkers({
        type: 'lot',
        markers: lots.value as unknown as MapMarker[]
      })
    }

    return { 
      activeExcludedLots,
      addUniqueMarkers,
      clearLotsAndComps,
      comps, 
      comps_sale,
      comps_sold,
      clearPolygon, 
      filterComps, 
      filterLots,
      lots, 
      totalLots,
      mdnDaysOnMarket,
    }
  })