import { z } from 'zod';

export type CampaignStatus = 
  'pending' | 'in_progress' | 'failed' | 'completed' | 'paused' 
  | 'mail_sent' | 'mail_delivered'


export type CampaignUpdateForm = {
  id: string;
  name: string;
  conversions: number
}

export const CampaignMapColumnValidation = z.object({
  apn: z.string().min(1, 'APN is required'),
  owner_name: z.string().min(1, 'Owner Name column is required'),
  owner_city: z.string().min(1, 'Owner City column is required'),
  owner_address: z.string().min(1, 'Owner Address column is required'),
  owner_state: z.string().min(1, 'Owner Status column is required'),
  owner_zip: z.string().min(1, 'Owner Zip column is required'),
  acres: z.string().min(1, 'Acres column is required'),
  lat: z.string().min(1, 'Latitude column is required'),
  lng: z.string().min(1, 'Longitude column is required'),
})

export type CampaignCSVColumns = z.infer<typeof CampaignMapColumnValidation>

export type CSVColumnPropertyKey = 
  'apn' | 'owner_name' | 'owner_city' |
  'owner_address' | 'owner_state' | 'owner_zip' |
  'acres' | 'lat' | 'lng'
