import { ref } from 'vue'
import { defineStore } from 'pinia';
import api from '@/lib/axios';
import * as Sentry from '@sentry/vue'
import { RESPONSE } from '@/types/enums';
import type { LotsApiResponse } from '@/types/api-response/lots';
import { useMapHelpers } from '@/composables/google-map/useMapHelpers';
import { useCampaignStore } from '../campaign/campaign';
import { useAdvancedFilters } from '@/composables/useAdvancedFilters';
import { useFilters } from '@/composables/useFilters';
import { useExploreStore } from '../explore';
import type { MapMarker } from '@/types/mapMarkers';

export const useLotScrubbing = defineStore('lot-scrub', () => {
  const campaign = useCampaignStore()
  const explore = useExploreStore()

  const filter = useFilters()
  const advancedFilter = useAdvancedFilters()
  const { selectedPolygon } = useMapHelpers()

  const lots = ref<MapMarker[]>([])
  const isProcessing = ref<boolean>(false)

  //DO NOT APPLY MIN, MAX LAT LNG HERE.
  //USE THIS FOR RETRIEVING LOTS WHEN USER REQUEST
  //A SPECIFIC LOT FROM SCRUBBING.

  //DO NOT ADD SAVING OF CAMPAIGN AND OTHER CONDITIONAL 
  //STATEMENT SUCH AS CHECKING IF LOT/COMP IS ACTIVE
  //IN THE MAP LEGEND. THIS WILL ONLY BE INVOKED UPON
  //THE SCRUBBING ONLY.

  //eslint-disable-next-line complexity 
  const fetchLots = async ({
    min_lot_number,
    max_lot_number,
  }: { 
    min_lot_number: number,
    max_lot_number: number 
  }): Promise<void> => {
    const { 
      min_price: min_estimated_price, min_acres,
      max_price: max_estimated_price, max_acres,
    } = filter.filters

    isProcessing.value = true
    const response = await api.post<LotsApiResponse>(
      'lots', {
        min_estimated_price, max_estimated_price, min_acres, 
        max_acres, min_lot_number, max_lot_number,
        polygon: selectedPolygon.value,
        campaign_id: campaign.selectedCampaign?.id,
        ...advancedFilter.getAdvancedFilter()
      },
    )   
    //Reset fetchData to any
    filter.fetchData.value = 'any'
    isProcessing.value = false

    if (response.status === RESPONSE.SUCCESS) {
      lots.value = response.data.data! as unknown as MapMarker[]
      //If the requested lot is not returned or doesn't exist
      //log to sentry for easy debugging.
      if (lots.value.length === 0) {
        Sentry.captureMessage(`
          Error (Scrub): No data returned for the requested lots for 
          scrubbing having a min lot of ${min_lot_number} and max 
          lot number of ${max_lot_number}. 
        `)
        return
      }
      explore.addUniqueMarkers(lots.value)
    }
  }

  return { 
    fetchLots,
    isProcessing,
    lots, 
  }
})