import { ref, shallowRef } from 'vue'
import { useExploreStore } from '@/store/explore';
import type { Polygon } from '@/types/comps-lots';

const compSaleMarkers = 
  shallowRef<google.maps.marker.AdvancedMarkerElement[]>([]);
const compSoldMarkers = 
  shallowRef<google.maps.marker.AdvancedMarkerElement[]>([]);
const lotMarkers = shallowRef<google.maps.marker.AdvancedMarkerElement[]>([]);
const selectedPolygon = shallowRef<Polygon[]>([]);

const hasParcelLines = ref<boolean>(false)
const drawOnMap = ref<boolean>(true)

export function useMapHelpers () {
  const store = useExploreStore()

  const onShapeDrawn = async (
    shape: google.maps.Polyline,
    isDrawn?: boolean
  ) => {
    const polygon = shape.getPath().getArray()
      .map(coord => ({
        lat: coord.lat(), 
        lng: coord.lng()
      }))

    selectedPolygon.value = polygon
    store.clearLotsAndComps()
    Promise.all([store.filterLots(isDrawn), store.filterComps(isDrawn)])
  }

  const disable = (map: google.maps.Map) => {
    map.setOptions({ draggable: false });
  }
  
  const enable = (map: google.maps.Map) => {
    map.setOptions({ draggable: true, draggableCursor: 'grab' });
  }

  const toggleDrawOnMap = (active: boolean) => {
    drawOnMap.value = active
  }

  const toggleParcelLines = (map: google.maps.Map) => {
    map.data.forEach(feature => {
      map.data.remove(feature)
    })
    hasParcelLines.value = false 
  }

  return {
    disable,
    enable,
    hasParcelLines,
    onShapeDrawn,
    compSaleMarkers,
    compSoldMarkers,
    lotMarkers,
    selectedPolygon,
    toggleDrawOnMap,
    toggleParcelLines,
    drawOnMap,
  }
}